* {
  font-family: "Roboto Flex", sans-serif;
  color: #EFE9F4;
}

.App {
  text-align: center;
}

.body {
  background-color: var(--bg-color);
}

.App-body {
  --bg-color: #000001;
  --text-color: #EFE9F4;
  --title-text-color: #D56062;
  --secondary-text-color: #84BCDA;
  --link-text-color: #84BCDA;

  background-color: var(--bg-color);
  /* height: 100dvh; */
  /* max-width: 700px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: var(--text-color);
}

.App-body-top {
  background-color: var(--bg-color);
  /* height: 100svh; */
  /* max-height: 100svh; */
  max-width: 900px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  /* font-size: calc(10px + 2vmin); */
  color: var(--text-color);
  white-space: pre-line;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.remove-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.remove-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12;
  border-radius: 10;
  border-width: 1;
  border-color: #888;
  background-color: white;
  color: black;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.text {
  font-size: calc(12x + 1vmin);
  font-size: calc(12px + 1vmin);
  font-weight: bold;
}

.threelinetext {
  font-size: calc(15x + 1vmin);
  font-size: calc(15px + 1vmin);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  justify-content: flex-start;
}

.onelinetextplain {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.onelinetext {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  justify-content: flex-start;
}

.onelinetextshow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: none;
  text-align: left;
  justify-content: flex-start;
}

.onelinetext-montserrat {
  font-optical-sizing: auto;
  font-style: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  justify-content: flex-start;
}

.onelinetextclip {
  font-size: calc(15x + 1vmin);
  font-size: calc(15px + 1vmin);
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: visible;
  text-overflow: clip;
  text-align: left;
  justify-content: flex-start;
  white-space: nowrap;
}

.search-modal {
  width: 90vw;
  /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.ffbutton-text {
  font-family: "IBM Plex Mono", sans-serif;
}

.App-header {
  background-color: #17171E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-monotext {
  font-family: "IBM Plex Mono", sans-serif;
  font-style: normal;
}

.App-title {
  font-size: calc(20px + 2vmin);
  line-height: calc(20px + 2vmin);
  font-weight: bold;
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--title-text-color)
}

.App-hometext {
  font-size: calc(15px);
  line-height: 1;
  font-weight: bold;
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App-subtitle {
  font-size: calc(15px + 1vmin);
  line-height: calc(15px + 1vmin);
  font-weight: bold;
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--secondary-text-color)
}

.App-artistsubtitle {
  font-size: calc(15px + 2vmin);
  font-size: calc(15px + 2vmin);
  line-height: 1;
  font-weight: bold;
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App-headertop {
  /* background-color: #17171E; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App-headertitle {
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: calc(17px + 2vmin);
  font-weight: bold;
}

.App-headertitleoneline {
  font-size: calc(17px + 2vmin);
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  justify-content: flex-start;
}

.App-bluroverlay {
  -webkit-backdrop-filter: blur(5px);
  /* Add this line first, it fixes blur for Safari*/
  backdrop-filter: blur(5px);
  /* This line activates blur*/
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: #17171Edd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-nesteddropdown {
  padding: 0
}

.App-modal {
  min-width: 80vw;
}

.App-modaltitle {
  width: "100%";
  background-color: 'red';
  text-align: center;
}

.App-backarrow {
  font-size: calc(12px + 1vmin);
}

.App-tertiarytitle {
  font-size: calc(17px + 0.7vmin);
  font-weight: bold;
  white-space: nowrap;
}

.App-tertiarytitleoneline {
  font-size: calc(17px + 0.7vmin);
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  justify-content: flex-start;
}

.App-typetitle {
  font-size: calc(10px + 1vmin);
  font-weight: 500;
}

.App-smalltext {
  font-size: calc(12px + 0.3vmin);
  line-height: calc(12px + 0.3vmin);
}

.App-tertiarytoggle {
  font-size: calc(12px + 1vmin);
  font-size: calc(12px + 1vmin);
}

.App-toggletitle {
  font-size: calc(12px + 2vmin);
  font-size: calc(12px + 2vmin);
  font-weight: bold;
}

.App-normaltext {
  font-size: calc(15px + 0.5vmin);
}

.App-montserrat-normaltext {
  font-size: calc(15px + 0.5vmin);
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App-montserrat-aitext {
  font-size: calc(15px + 0.5vmin);
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background: linear-gradient(90deg, #FCC679, #e293ae);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

.App-grid-container {
  display: grid;
  margin: auto;
}

.App-montserrat-smallertext {
  font-size: calc(10px + 0.5vmin);
  font-family: "IBM Plex Mono", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App-modal .modal-content {
  background-color: #17171E;
}

/* .App-animated-ease {
  -webkit-animation: Animation 3s ease infinite;
  -moz-animation: Animation 3s ease infinite;
  animation: Animation 3s ease infinite;
  -webkit-transition: .2s;
  transition: height .2s;
} */

.App-animated-gradient {
  background: linear-gradient(90deg, #D98205, #CA3C6D, #D98205);
  /* background: linear-gradient(90deg, #FCC679, #d5648b, #FCC679); */
  background-size: 200% 200%;
  -webkit-animation: Animation 3s ease infinite;
  -moz-animation: Animation 3s ease infinite;
  animation: Animation 3s ease infinite;
  -webkit-transition: .2s;
  transition: width .2s;
}

.App-animated-gradient-light {
  background: linear-gradient(90deg, #FCC679, #e293ae, #FCC679);
  /* background: linear-gradient(90deg, #FCC679, #d5648b, #FCC679); */
  background-size: 200% 200%;
  -webkit-animation: Animation 3s ease infinite;
  -moz-animation: Animation 3s ease infinite;
  animation: Animation 3s ease infinite;
  -webkit-transition: .2s;
  transition: width .2s;
}

.App-animated-gradient-fast {
  background: linear-gradient(90deg, #D98205, #CA3C6D, #D98205);
  /* background: linear-gradient(90deg, #FCC679, #d5648b, #FCC679); */
  background-size: 200% 200%;
  -webkit-animation: Animation 2s ease infinite;
  -moz-animation: Animation 2s ease infinite;
  animation: Animation 2s ease infinite;
  -webkit-transition: .2s;
  transition: width .2s;
}

.App-animated-gradient-fast-light {
  background: linear-gradient(90deg, #FCC679, #e293ae, #FCC679);
  /* background: linear-gradient(90deg, #FCC679, #d5648b, #FCC679); */
  background-size: 200% 200%;
  -webkit-animation: Animation 2s ease infinite;
  -moz-animation: Animation 2s ease infinite;
  animation: Animation 2s ease infinite;
  -webkit-transition: .2s;
  transition: width .2s;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

/* unvisited link */
a:link {
  color: var(--link-text-color);
  /* text-decoration: none */
  text-decoration: underline
}

/* visited link */
a:visited {
  color: var(--link-text-color);
  text-decoration: none
}

/* mouse over link */
a:hover {
  color: var(--link-text-color);
  /* color: var("#ff0000"); */

  opacity: 0.8;
  text-decoration: underline
}

/* selected link */
a:active {
  color: var(--link-text-color);
  opacity: 0.8;
  text-decoration: none;
}




/* DROPDOWNSTYLES */
.rnd {
  position: relative;
  width: fit-content;
}

.rnd * {
  box-sizing: border-box;
}

.rnd__root-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin: 7px 0;
  z-index: 10;
}

.rnd__root-menu.rnd__menu--top {
  top: auto;
  bottom: 100%;
}

.rnd__root-menu.rnd__menu--bottom {
  top: 100%;
  bottom: auto;
}

.rnd__root-menu.rnd__menu--right {
  left: 0;
  right: auto;
}

.rnd__root-menu.rnd__menu--left {
  right: 0;
  left: auto;
}

.rnd__menu {
  /* overflow: hidden; */
  background-color: #343a40;
  /* border: 1px solid hsl(0, 0%, 90%); */
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 0px 0;
  list-style: none;
  font-family: "IBM Plex Mono", sans-serif;
}

.rnd__submenu {
  position: absolute;
  display: none;
  opacity: 0;
  left: 100%;
  top: 0;
}

.rnd__submenu--opened {
  opacity: 1;
}

.rnd__submenu.rnd__menu--top {
  top: auto;
  bottom: 0;
}

.rnd__submenu.rnd__menu--bottom {
  top: 0;
  bottom: auto;
}

.rnd__submenu.rnd__menu--right {
  left: 100%;
  right: auto;
}

.rnd__submenu.rnd__menu--left {
  right: 100%;
  left: auto;
}

.rnd__option {
  padding: 12px 15px;
  cursor: pointer;
  word-break: break-word;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
}

.rnd__option--with-menu:hover>.rnd__submenu {
  display: block;
}

.rnd__option:not(.rnd__option--disabled):hover {
  border-radius: 12px;
  background-color: hsla(0, 0%, 0%, 0.1);
}

.rnd__option--disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.rnd__option-icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rnd__option-icon--left {
  margin-right: 7px;
}

.rnd__option-icon--right {
  margin-left: auto;
}

.rnd__option-label {
  font-weight: 600;
  font-size: 11px;
  margin: 0;
}